@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body, html{
    min-height: 100%; 
    /* display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif; */
}
.wrapper{
    background-color: #fff;
    height: 100%;
    width: 100%;
    justify-content: center;
}
.topBarTitle{
    background-color: #F02626;
    height: 90px ;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    line-height: 110px !important;
}
.group {
    padding: 15px 30px;
}
.nameOfBusiness{
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    transition: 0.2s;
}
.labelText{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.optionalText{
    color: #808080;
    font-size: 14px;
    font-weight: 600;
}
.ratingSelectInput{
    width: 250px;
    padding: 12px 10px;
    margin: 8px 0;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #808080 !important;
}
.inputText{
    padding: 10px 10px;
    margin: 8px 0;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    border: 1.2px solid #000;
    color: #000;
    width: 100%;
}
.btnSubmit{
    text-align: center;
}
.btnSubmit button{
    font-size: 16px;
    background-color: #F02626;
    color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    width: 125px;
    font-weight: 600;
}
.btn:focus{
    box-shadow: none;
    outline: none;
    border: none;
}
.btn:hover{
    color: #fff !important;
}
.inputText::placeholder{
    color: #000;
}
.form-control:focus{
    outline: none;
    box-shadow: none;
    border-color: #000;
}

.form-control:disabled{
    background-color: #fff;
}